var form = document.getElementById('become-betatester')

async function handleSubmit (event) {
  event.preventDefault()
  var data = new FormData(event.target)
  console.log(data, event.target)
  fetch(event.target.action, {
    method: form.method,
    body: data,
    headers: {
      'Accept': 'application/json'
    }
  }).then(response => {
    if (response.ok) {
      swal('Perfetto', 'Ti ricontatteremo presto!', 'success')
      form.reset()
    } else {
      response.json().then(data => {
        swal('Ooopss..', 'Non è stat possibile inviare la richiesta', 'error')
      })
    }
  }).catch(error => {
    swal('Ooopss..', 'Non è stat possibile inviare la richiesta', 'error')
  })
}

form.addEventListener('submit', handleSubmit)
